/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/
import { css } from '@spectrum-web-components/base';
const styles = css`
:host{--spectrum-tabs-focus-ring-border-radius:var(
--spectrum-tabs-m-focus-ring-border-radius
);--spectrum-tabs-compact-item-height:var(
--spectrum-tabs-m-compact-item-height,var(--spectrum-alias-item-height-m)
);--spectrum-tabs-compact-vertical-item-height:var(
--spectrum-tabs-m-compact-vertical-item-height,var(--spectrum-global-dimension-size-400)
);--spectrum-tabs-compact-vertical-item-gap:var(
--spectrum-tabs-m-compact-vertical-item-gap,var(--spectrum-global-dimension-size-50)
);--spectrum-tabs-text-font-weight:var(
--spectrum-tabs-m-text-font-weight,var(--spectrum-alias-body-text-font-weight)
);--spectrum-tabs-rule-border-radius:var(
--spectrum-tabs-m-rule-border-radius,var(--spectrum-global-dimension-static-size-10)
);--spectrum-tabs-rule-size:var(
--spectrum-tabs-m-rule-size,var(--spectrum-alias-border-size-thick)
);--spectrum-tabs-focus-ring-size:var(
--spectrum-tabs-m-focus-ring-size,var(--spectrum-alias-border-size-thick)
);--spectrum-tabs-text-size:var(
--spectrum-tabs-m-text-size,var(--spectrum-alias-item-text-size-m)
);--spectrum-tabs-height:var(
--spectrum-tabs-m-height,var(--spectrum-global-dimension-size-600)
);--spectrum-tabs-item-height:var(
--spectrum-tabs-m-item-height,var(--spectrum-alias-item-height-m)
);--spectrum-tabs-icon-gap:var(
--spectrum-tabs-m-icon-gap,var(--spectrum-alias-item-workflow-icon-gap-m)
);--spectrum-tabs-focus-ring-height:var(
--spectrum-tabs-m-focus-ring-height,var(--spectrum-alias-item-height-m)
);--spectrum-tabs-focus-ring-padding-x:var(
--spectrum-tabs-m-focus-ring-padding-x,var(--spectrum-alias-item-padding-m)
);--spectrum-tabs-item-gap:var(
--spectrum-tabs-m-item-gap,var(--spectrum-global-dimension-size-300)
);--spectrum-tabs-selection-indicator-animation-duration:var(
--spectrum-tabs-m-selection-indicator-animation-duration,var(--spectrum-global-animation-duration-100)
);--spectrum-tabs-vertical-rule-size:var(
--spectrum-tabs-m-vertical-rule-size,var(--spectrum-alias-border-size-thick)
);--spectrum-tabs-vertical-item-height:var(
--spectrum-tabs-m-vertical-item-height,var(--spectrum-global-dimension-size-550)
);--spectrum-tabs-vertical-item-gap:var(
--spectrum-tabs-m-vertical-item-gap,var(--spectrum-global-dimension-size-50)
);--spectrum-tabs-vertical-item-margin-left:var(
--spectrum-tabs-m-vertical-item-margin-left,var(--spectrum-global-dimension-size-150)
);--spectrum-tabs-quiet-compact-height:var(
--spectrum-tabs-quiet-m-compact-height,var(--spectrum-alias-item-height-m)
)}:host{--spectrum-tabs-item-height:calc(var(--spectrum-tabs-height) - var(--spectrum-tabs-rule-size));--spectrum-tabs-compact-item-height:calc(var(--spectrum-tabs-quiet-compact-height) - var(--spectrum-tabs-rule-size));--spectrum-tabs-text-size:var(
--spectrum-alias-font-size-default,var(--spectrum-global-dimension-font-size-100)
)}#list{display:flex;margin:0;padding-bottom:0;padding-left:var(--spectrum-tabs-focus-ring-padding-x);padding-right:var(--spectrum-tabs-focus-ring-padding-x);padding-top:0;position:relative;vertical-align:top;z-index:0}:host([dir=ltr]) ::slotted(*):before{left:calc(-1*var(--spectrum-tabs-focus-ring-padding-x))}:host([dir=rtl]) ::slotted(*):before{right:calc(-1*var(--spectrum-tabs-focus-ring-padding-x))}:host([dir=ltr]) ::slotted(*):before{right:calc(-1*var(--spectrum-tabs-focus-ring-padding-x))}:host([dir=rtl]) ::slotted(*):before{left:calc(-1*var(--spectrum-tabs-focus-ring-padding-x))}:host([dir=ltr]) #selectionIndicator{left:0}:host([dir=rtl]) #selectionIndicator{right:0}#selectionIndicator{border-radius:var(--spectrum-tabs-rule-border-radius);position:absolute;transform-origin:top left;transition:transform var(--spectrum-tabs-selection-indicator-animation-duration) ease-in-out;z-index:0}:host([compact]) ::slotted(*){height:var(
--spectrum-tabs-compact-item-height
);line-height:var(--spectrum-tabs-compact-item-height)}:host([direction=horizontal]) #list{align-items:center;border-bottom:var(--spectrum-tabs-rule-size) solid}:host([direction=horizontal]) ::slotted(*){vertical-align:top}:host([dir=ltr][direction=horizontal]) ::slotted(:not([slot]):not(:first-child)){margin-left:var(
--spectrum-tabs-item-gap
)}:host([dir=rtl][direction=horizontal]) ::slotted(:not([slot]):not(:first-child)){margin-right:var(
--spectrum-tabs-item-gap
)}:host([direction=horizontal]) #selectionIndicator{bottom:0;bottom:calc(-1*var(--spectrum-tabs-rule-size));height:var(--spectrum-tabs-rule-size);position:absolute}:host([direction=horizontal][compact]) #list{align-items:end;box-sizing:content-box;height:var(--spectrum-tabs-compact-item-height)}:host([quiet]) #list{display:inline-flex}:host([dir=ltr][direction=vertical]) #list{border-left:var(--spectrum-tabs-vertical-rule-size) solid}:host([dir=rtl][direction=vertical]) #list{border-right:var(--spectrum-tabs-vertical-rule-size) solid}:host([direction=vertical]) #list{display:inline-flex;flex-direction:column;padding:0}:host([dir=ltr][direction=vertical]) ::slotted(*){margin-left:calc(var(--spectrum-tabs-vertical-item-margin-left) - var(--spectrum-tabs-focus-ring-padding-x))}:host([dir=rtl][direction=vertical]) ::slotted(*){margin-right:calc(var(--spectrum-tabs-vertical-item-margin-left) - var(--spectrum-tabs-focus-ring-padding-x))}:host([direction=vertical]) ::slotted(*){height:var(
--spectrum-tabs-vertical-item-height
);margin-bottom:var(--spectrum-tabs-vertical-item-gap);padding-bottom:0;padding-left:var(--spectrum-tabs-focus-ring-padding-x);padding-right:var(--spectrum-tabs-focus-ring-padding-x);padding-top:0}:host([dir=ltr][direction=vertical]) ::slotted(*):before{left:calc(-1*var(--spectrum-tabs-focus-ring-size))}:host([dir=rtl][direction=vertical]) ::slotted(*):before{right:calc(-1*var(--spectrum-tabs-focus-ring-size))}:host([dir=ltr][direction=vertical]) ::slotted(*):before{right:calc(-1*var(--spectrum-tabs-focus-ring-size))}:host([dir=rtl][direction=vertical]) ::slotted(*):before{left:calc(-1*var(--spectrum-tabs-focus-ring-size))}:host([direction=vertical]) ::slotted(*):before{margin-top:calc(var(--spectrum-tabs-focus-ring-height)/-2)}:host([direction=vertical][compact]) #list ::slotted(*){height:var(
--spectrum-tabs-compact-vertical-item-height
);line-height:var(--spectrum-tabs-compact-vertical-item-height);margin-bottom:var(--spectrum-tabs-compact-vertical-item-gap)}:host([dir=ltr][direction=vertical]) #selectionIndicator{left:0}:host([dir=rtl][direction=vertical]) #selectionIndicator{right:0}:host([dir=ltr][direction=vertical]) #selectionIndicator{left:calc(-1*var(--spectrum-tabs-vertical-rule-size))}:host([dir=rtl][direction=vertical]) #selectionIndicator{right:calc(-1*var(--spectrum-tabs-vertical-rule-size))}:host([direction=vertical]) #selectionIndicator{position:absolute;width:var(--spectrum-tabs-vertical-rule-size)}#list{border-bottom-color:var(
--spectrum-tabs-m-rule-color,var(--spectrum-alias-border-color-light)
)}:host([dir=ltr][direction=vertical]) #list{border-left-color:var(
--spectrum-tabs-m-vertical-rule-color,var(--spectrum-alias-border-color-light)
)}:host([dir=rtl][direction=vertical]) #list{border-right-color:var(
--spectrum-tabs-m-vertical-rule-color,var(--spectrum-alias-border-color-light)
)}#selectionIndicator{background-color:var(
--spectrum-tabs-m-selection-indicator-color,var(--spectrum-global-color-gray-900)
)}:host([quiet]) #list{border-bottom-color:var(
--spectrum-tabs-quiet-m-rule-color,var(--spectrum-alias-border-color-transparent)
)}:host([quiet]) #selectionIndicator{background-color:var(
--spectrum-tabs-quiet-m-selection-indicator-color,var(--spectrum-global-color-gray-900)
)}:host([dir=ltr][direction=vertical][compact]) #list,:host([dir=ltr][direction=vertical][quiet]) #list{border-left-color:var(
--spectrum-tabs-quiet-m-vertical-rule-color,var(--spectrum-alias-border-color-transparent)
)}:host([dir=rtl][direction=vertical][compact]) #list,:host([dir=rtl][direction=vertical][quiet]) #list{border-right-color:var(
--spectrum-tabs-quiet-m-vertical-rule-color,var(--spectrum-alias-border-color-transparent)
)}:host([direction=vertical][compact]) #list #selectionIndicator,:host([direction=vertical][quiet]) #list #selectionIndicator{background-color:var(
--spectrum-tabs-quiet-m-selection-indicator-color,var(--spectrum-global-color-gray-900)
)}:host{display:grid}:host([direction^=vertical]){grid-template-columns:auto 1fr}:host([disabled]) #selectionIndicator{background-color:var(
--spectrum-tabs-m-text-color-disabled,var(--spectrum-alias-text-color-disabled)
)}:host([disabled]) ::slotted(sp-tab){color:var(
--spectrum-tabs-m-text-color-disabled,var(--spectrum-alias-text-color-disabled)
)}#list{justify-content:var(--swc-tabs-list-justify-content)}:host([disabled]) #list{pointer-events:none}:host([direction=vertical-right]) #selectionIndicator,:host([direction=vertical]) #selectionIndicator{height:1px;top:0}:host([compact]){--spectrum-tabs-height:var(--spectrum-tabs-quiet-compact-height)}:host([direction=horizontal]:not([quiet])) #list{border-bottom-color:var(
--spectrum-tabs-rule-color,var(--spectrum-global-color-gray-200)
)}:host([dir][direction=horizontal]) #selectionIndicator{width:1px}:host([dir=ltr][direction=vertical-right]) #list{border-right:var(
--spectrum-tabs-vertical-rule-width,var(--spectrum-alias-border-size-thick)
) solid;border-right-color:var(
--spectrum-tabs-vertical-rule-color,var(--spectrum-global-color-gray-200)
);display:inline-flex;flex-direction:column;padding:0}:host([dir=rtl][direction=vertical-right]) #list{border-left:var(
--spectrum-tabs-vertical-rule-width,var(--spectrum-alias-border-size-thick)
) solid;border-left-color:var(
--spectrum-tabs-vertical-rule-color,var(--spectrum-global-color-gray-200)
);display:inline-flex;flex-direction:column;padding:0}:host([dir=ltr][direction=vertical-right]) ::slotted(*){height:var(
--spectrum-tabs-vertical-item-height,var(--spectrum-global-dimension-size-550)
);margin-bottom:var(
--spectrum-tabs-vertical-item-gap,var(--spectrum-global-dimension-size-50)
);margin-right:calc(var(
--spectrum-tabs-vertical-item-margin-left,
var(--spectrum-global-dimension-size-150)
) - var(
--spectrum-tabs-focus-ring-padding-x,
var(--spectrum-global-dimension-size-100)
));padding:0 var(
--spectrum-tabs-focus-ring-padding-x,var(--spectrum-global-dimension-size-100)
)}:host([dir=rtl][direction=vertical-right]) ::slotted(*){height:var(
--spectrum-tabs-vertical-item-height,var(--spectrum-global-dimension-size-550)
);margin-bottom:var(
--spectrum-tabs-vertical-item-gap,var(--spectrum-global-dimension-size-50)
);margin-left:calc(var(
--spectrum-tabs-vertical-item-margin-left,
var(--spectrum-global-dimension-size-150)
) - var(
--spectrum-tabs-focus-ring-padding-x,
var(--spectrum-global-dimension-size-100)
));padding:0 var(
--spectrum-tabs-focus-ring-padding-x,var(--spectrum-global-dimension-size-100)
)}:host([direction=vertical-right][compact]) ::slotted(*){height:var(
--spectrum-tabs-compact-vertical-item-height,var(--spectrum-global-dimension-size-400)
);line-height:var(
--spectrum-tabs-compact-vertical-item-height,var(--spectrum-global-dimension-size-400)
);margin-bottom:var(
--spectrum-tabs-compact-vertical-item-gap,var(--spectrum-global-dimension-size-50)
)}:host([dir=ltr][direction=vertical-right]) #selectionIndicator{left:auto;position:absolute;right:calc(-1*var(--spectrum-tabs-vertical-rule-width, var(--spectrum-alias-border-size-thick)));width:var(
--spectrum-tabs-vertical-rule-width,var(--spectrum-alias-border-size-thick)
)}:host([dir=rtl][direction=vertical-right]) #selectionIndicator{left:calc(-1*var(--spectrum-tabs-vertical-rule-width, var(--spectrum-alias-border-size-thick)));position:absolute;right:auto;width:var(
--spectrum-tabs-vertical-rule-width,var(--spectrum-alias-border-size-thick)
)}:host([dir=ltr][direction=vertical-right][compact]) #list,:host([dir=ltr][direction=vertical-right][quiet]) #list{border-right-color:var(
--spectrum-tabs-quiet-vertical-rule-color,var(--spectrum-alias-border-color-transparent)
)}:host([dir=rtl][direction=vertical-right][compact]) #list,:host([dir=rtl][direction=vertical-right][quiet]) #list{border-left-color:var(
--spectrum-tabs-quiet-vertical-rule-color,var(--spectrum-alias-border-color-transparent)
)}:host([direction=vertical-right][compact]) #selectionIndicator,:host([direction=vertical-right][quiet]) #selectionIndicator{background-color:var(
--spectrum-tabs-quiet-selection-indicator-color,var(--spectrum-global-color-gray-900)
)}#selectionIndicator.first-position{transition:none}
`;
export default styles;